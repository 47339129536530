<template>
    <div>
        <div class="">
            <div class="mb-4">
                <label for="email" class="form-label text-18 form__title">
                    <p>Adres e-mail</p>
                </label>
                <input id="email" placeholder="Adres e-mail" class="form-control form__textarea"
                    :class="{ 'form__textarea-error': error }" v-model="email">
                <div class="text-danger" v-if="errors && errors.email">
                    {{ errors.email[0] }}
                </div>
            </div>
            <div class="mb-4">
                <label for="phone" class="form-label text-18 form__title">
                    <p>Numer telefonu</p>
                </label>
                <input id="phone" placeholder="Numer telefonu" class="form-control form__textarea"
                    :class="{ 'form__textarea-error': error }" v-model="phone">
                <div class="text-danger" v-if="errors && errors.phone">
                    {{ errors.phone[0] }}
                </div>
            </div>
            <div class="text-danger mb-2" v-if="error">
                {{ error }}
            </div>
        </div>
        <div class="d-flex justify-content-start">
            <button @click="sendForm" class="button__red" :disabled="disabled">
                Zagłosuj
                <img src="@/assets/right-btn.svg" alt="" class="button__arrow" />
            </button>
        </div>
        <div>
            <div class="heading-16 form__pt-24">
                <p class="text-start heading-16 form__description">
                    <span>Administratorem danych osobowych/współadministratorem danych osobowych zbieranych za
                        pośrednictwem Strony Internetowej jest: Enea S.A.</span>
                    <span class="text-start text-16" v-if="!moreInfo"> ...
                        <span class="heading-16 cur-pointer" @click="moreInfo = true"> rozwiń</span>
                    </span>
                    <span v-if="moreInfo"> z siedzibą w Poznaniu (60-198), przy Pastelowej 8, wpisana do rejestru
                        przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto
                        i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS
                        0000012483, NIP 777-00-20-640, REGON: 630139960, kapitał zakładowy: 441 442 578 zł wpłacony
                        w całości (dalej „Organizator” lub „Administrator danych osobowych” lub „Współadministrator
                        danych osobowych”), <br>
                        Współorganizatorem Akcji i administratorem danych osobowych/współadministratorem danych
                        osobowych zbieranych za pośrednictwem Strony Internetowej jest Fundacja Enea z siedzibą w
                        Poznaniu (60-198), przy Pastelowej 8, wpisana do rejestru stowarzyszeń, innych organizacji
                        społecznych i zawodowych, fundacji oraz samodzielnych publicznych zakładów opieki zdrowotnej
                        Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda, VIII
                        Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000505487, NIP: 779-24-21-429,
                        REGON: 302710647. Fundacja Enea jest jednocześnie fundatorem nagród w Akcji (dalej
                        „Współorganizator” lub „Administrator danych osobowych” lub „Współadministrator danych
                        osobowych”).<br>
                        Dane osobowe Użytkowników w rozumieniu Regulaminu Strony Internetowej
                        www.akademiatalentow.enea.pl są przetwarzane na podstawie Rozporządzenia Parlamentu
                        Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych
                        w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz
                        uchylenia dyrektywy 95/46/WE („RODO”) - Dz. U. UE. L. z 2016 r. Nr 119/1 oraz zgodnie z ustawą o
                        świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r.
                        Współadministratorzy danych osobowych i Administratorzy danych osobowych dokładają szczególnej
                        staranności w celu ochrony interesów podmiotów, których dane dotyczą, a w szczególności
                        zapewniają, że zbierane przez nich dane są przetwarzane zgodnie z prawem; zbierane dla
                        oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi
                        celami; merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane oraz
                        przechowywane w postaci umożliwiającej identyfikację podmiotów, których dotyczą, nie dłużej niż
                        jest to niezbędne do osiągnięcia celu przetwarzania. <br>
                        Podstawą przetwarzania danych osobowych jest art. 6 ust. 1 lit. a), b) i f) RODO.
                        Dane osobowe będą przetwarzane przez okres wskazywany w poszczególnych klauzulach informacyjnych
                        udostępnionych przez Współadministratorów. .<br>
                        Użytkownik ma prawo do:<br>
                        a. dostępu do swoich danych osobowych - w granicach art. 15 RODO,<br>
                        b. ich sprostowania – w granicach art. 16 RODO,<br>
                        c. ich usunięcia - w granicach art. 17 RODO,<br>
                        d. ograniczenia przetwarzania - w granicach art. 18 RODO,<br>
                        e. przenoszenia danych - w granicach art. 20 RODO,<br>
                        f. wniesienia sprzeciwu wobec przetwarzania danych osobowych opartego na art. 6 ust. 1 lit.
                        f)<br>
                        RODO - w granicach art. 21 RODO.<br><br>
                        W odniesieniu do danych osobowych przetwarzanych na podstawie zgody osoba, której dane dotyczą
                        ma prawo do cofnięcia zgody w dowolnym momencie, przy czym wycofanie zgody nie wpływa na
                        zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.
                        Cofnięcie zgody jest możliwe za pośrednictwem formularza kontaktowego na stronie:
                        www.akademiatalentow.enea.pl/kontakt.<br>
                        Współadministrator może ujawnić Użytkowników dane osobowe podmiotom upoważnionym na podstawie
                        przepisów prawa.<br>
                        Współadministrator może również powierzyć przetwarzanie danych osobowych Użytkowników dostawcom
                        usług lub produktów działającym na jego rzecz, w szczególności podmiotom świadczącym usługi IT,
                        księgowe, transportowe, serwisowe, agencyjne. Zgodnie z zawartymi z takimi podmiotami umowami
                        powierzenia przetwarzania danych osobowych, Współadministrator wymaga od tych dostawców usług
                        zgodnego z przepisami prawa, wysokiego stopnia ochrony prywatności i bezpieczeństwa danych
                        osobowych Użytkowników przetwarzanych przez nich w imieniu Współadministratora.<br>
                        W odniesieniu do danych osobowych przetwarzanych samodzielnie przez Administratora danych
                        osobowych, w celu realizacji uprawnień, o których mowa powyżej, Użytkownik może kontaktować się
                        poprzez:<br>
                        - w odniesieniu do Organizatora - przesłanie stosowanej wiadomości e – mail na adres
                        fundacja@fundacja.enea.pl lub pisemnie na adres: Fundacja Enea, Pastelowa 8, 60-198 Poznań.<br>
                        - w odniesieniu do Współorganizatora - przesłanie stosowanej wiadomości e – mail na adres
                        esa.iod@enea.pl lub pisemnie na adres: Inspektor Ochrony Danych (IOD) Enea S.A., Pastelowa 8,
                        60-198 Poznań.<br>
                        W odniesieniu do danych osobowych przetwarzanych w ramach współadministrowania
                        Współadministratorzy danych osobowych postanowili o ustanowieniu wspólnego punktu kontaktowego,
                        wobec czego w celu realizacji uprawnień, o których mowa powyżej Użytkownik może kontaktować się
                        poprzez przesłanie stosownej wiadomości na adres: esa.iod@enea.pl lub pisemnie na adres:
                        Inspektor Ochrony Danych (IOD) Enea S.A., Pastelowa 8, 60-198 Poznań
                        Każdy ze Współadministratorów danych osobowych ponosi solidarną odpowiedzialność za wszelkie
                        naruszenia w procesie przetwarzania danych osobowych objętych współadministrowaniem. Osoba
                        której dane dotyczą może zgłosić swoje roszczenia wybranemu Współadministratorowi, który będzie
                        zobowiązany do naprawienia szkody, nawet jeśli wyrządził ją inny Współadministrator. Tym samym,
                        osoba, której dane dotyczą, ma prawo do kierowania roszczeń przeciwko któremukolwiek ze
                        Współadministratorów.<br>
                        Osoba, której dane dotyczą ma prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych
                        Osobowych, gdy uzna, iż przetwarzanie danych osobowych jej dotyczących narusza przepisy prawa
                        dotyczące przetwarzania danych osobowych; kontakt: ul. Stawki 2, 00-193 Warszawa, tel.: 22 531
                        03 00, fax: 22 531 03 01, e-mail: kancelaria@uodo.gov.pl.<br>
                        Dane osobowe nie będą przekazywane do państwa trzeciego/organizacji międzynarodowej.
                        W oparciu o Pani/Pana dane Współadministrator nie będzie podejmował decyzji w sposób
                        zautomatyzowany, w tym również w formie profilowania.</span>
                    <span v-if="moreInfo" @click="moreInfo = false"
                        class="form__checkmark-collapse text-start heading-16 cur-pointer">
                        zwiń</span>
                </p>
            </div>
        </div>
        <div v-if="showModal.show" class="modal fade show d-flex justify-content-center" id="staticBackdrop"
            style="display: block">
            <div class="modal__dialog">
                <div class="container">
                    <div class="modal__content col-sm-20">
                        <div class="modal-header">
                            <div v-if="showModal.type == 'success'" class="modal-body">
                                <p class="modal-title heading-32 text-center form__title" id="staticBackdropLabel">
                                    Zatwierdź swój głos!</p>
                            </div>
                            <div v-if="showModal.type == 'server-error'" class="modal-body">
                                <p class="modal-title heading-32 text-center form__title" id="staticBackdropLabel">
                                    Błąd serwera!</p>
                            </div>
                            <div v-if="showModal.type == 'validate-error'" class="modal-body">
                                <p class="modal-title heading-32 text-center form__title" id="staticBackdropLabel">
                                    {{ error }}</p>
                            </div>
                            <!-- <router-link :to="{ name: 'home' }" class="btn-close modal__btn-close"></router-link> -->
                            <span @click="showModal.show = false" class="btn-close modal__btn-close"></span>
                        </div>
                        <div v-if="showModal.type == 'success'" class="modal-body d-flex justify-content-center">
                            <div class="col-16 text-center">
                                <p class="modal__text text-20">Przed Tobą jeszcze jeden krok – wejdź w link wysłany na podany adres e-mail i wpisz kod weryfikujący, a Twój głos wpadnie na konto Twojego faworyta!</p>
                            </div>
                        </div>
                        <div v-if="showModal.type == 'server-error'" class="modal-body d-flex justify-content-center">
                            <div class="col-16 text-center">
                                <p class="modal__text text-20">Wystąpił nieoczekiwany błąd serwera.</p>
                            </div>
                        </div>

                        <div class="modal-footer d-flex justify-content-center">
                            <button type="button" class="button__blue" data-bs-dismiss="modal"
                                @click="showModal.show = false">Zostań na stronie formularza</button>
                        </div>
                        <div class="modal-body text-center mt-3">
                            <router-link :to="{ name: 'home' }" class="modal__extra-link text-16 form__title">Wróć do
                                strony
                                głównej</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    emits: [],
    data() {
        return {
            disabled: false,
            status: null,
            error: null,
            errors: {},
            email: null,
            phpne: null,
            showModal: {
                type: null,
                show: null
            },
            moreInfo: false
        }
    },
    props: ['id'],
    components: {
    },
    computed: {
    },
    watch: {
    },
    methods: {
        async sendForm() {
            this.disabled = true;
            this.errors = [];
            this.error = null;

            // await this.$recaptchaLoaded();
            // const token = await this.$recaptcha("form");
            const form = {
                email: this.email,
                phone: this.phone,
                id: this.id
            }

            axios
                .post(process.env.VUE_APP_API_BASE_URL + "vote", {
                    ...form,
                    // grecaptcha_token: token,
                })
                .then((response) => {
                    if (response.status == 200 && response.data.id) {
                        // this.status = 'success'
                        this.showModal.type = 'success';
                        this.showModal.show = true;
                    } else {
                        this.showModal.type = 'server-error';
                        this.showModal.show = true;
                        this.error = 'Błąd serwera'
                    }
                    this.disabled = false
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        this.errors = error.response.data.errors
                    } else if (error.response.data.status == 'error') {
                        this.error = error.response.data.message
                        this.showModal.type = 'validate-error';
                        this.showModal.show = true;

                    } else {
                        this.error = 'Błąd serwera'
                    }
                    this.disabled = false
                });
        },
    },
};
</script>